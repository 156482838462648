import * as React from 'react';

//Styling
import './Footer.scss';

const Footer = () => {
    return(
        <div className="footer-wrapper">
            <span>2021 Joan Boix Àvalos</span>
        </div>
    )
}

export default Footer;