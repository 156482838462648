import * as React from "react";

//Styling
import "./PersonalInfo.scss";

//i18n
import { useTranslation } from "react-i18next";

const PersonalInfo = () => {

  const [t,] = useTranslation("common");

  return (
    <div className="personalinfo-wrapper" id="about">
      <div className="personalinfo-title">
        <span>{t("about.title")}</span>
      </div>
      <div className="personalinfo-information">
        <div className="personalinfo-text">
          <p>{t("about.p1")}</p>
          <p>{t("about.p2")}</p>
          <p>{t("about.p3")}</p>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
