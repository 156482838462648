import * as React from "react";

//Styling
import "./Portfolio.scss";

//Child components
import ProjectCard from "./components/ProjectCard/ProjectCard";

//Utilities
import { projects } from "./currentProjects";

//i18n
import { useTranslation } from "react-i18next";

type Project = {
  name: string;
  link: string;
  color: string;
};

const Portfolio = () => {

  const [t,] = useTranslation('common');

  return (
    <div className="portfolio-wrapper" id="portfolio">
      <div className="portfolio-title">
        <span>{t('portfolio.title')}</span>
      </div>
      <div className="portfolio-projects">
        {projects.map((project: Project, index: number) => {
          return (
            <ProjectCard
              key={index}
              title={t('portfolio.'+project.name+'.title')}
              description={t('portfolio.'+project.name+'.description')}
              link={project.link}
              color={project.color}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Portfolio;
