import * as React from 'react';

//Styling
import './Navbar.scss';

//Material Icons
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

//i18n
import { useTranslation } from 'react-i18next';

const Navbar = () => {

    const [t,] = useTranslation('common')

    const navigateToTab = (id: string) => {
        document.getElementById(id)?.scrollIntoView();
    }

    return (
        <div className="navbar-wrapper">
            <div className="navbar-logo">
                <span onClick={() => navigateToTab("header")} >JB</span>
            </div>
            <div className="navbar-navigation">
                <div className="navbar-tabs">
                    <span onClick={() => navigateToTab("portfolio")}>{t('header.projects')}</span>
                    <span onClick={() => navigateToTab("about")}>{t('header.about')}</span>
                    <span onClick={() => navigateToTab("contact")}>{t('header.contact')}</span>
                </div>
                <div className="navbar-social">
                    <a href="https://github.com/joanB0ix" target="_blank" rel="noreferrer"><GitHubIcon /></a>
                    <a href="https://www.linkedin.com/in/joan-boix-avalos-737607164/" target="_blank" rel="noreferrer"><LinkedInIcon /></a>
                    <a href="https://www.instagram.com/joanboix_/" target="_blank" rel="noreferrer"><InstagramIcon /></a>
                    <a href="https://twitter.com/JoanBoixAv" target="_blank" rel="noreferrer"><TwitterIcon /></a>
                </div>
            </div>
        </div>
    )
}

export default Navbar;