import * as React from 'react';

//Styling 
import './Contact.scss';

//i18n
import { useTranslation } from 'react-i18next';

const Contact = () => {

    const [t,] = useTranslation('common');

    return(
        <div className="contact-wrapper" id="contact">
            <div className="contact-title">
                {t('contact.title')}
            </div>
            <div className="contact-information">
                <div className="contact-email">
                    <a href="mailto:joanboixavalos@gmail.com">joanboixavalos@gmail.com</a>
                </div>
            </div>
        </div>
    )
}

export default Contact;