import * as React from 'react';

//Styling
import './ProjectCard.scss';

type Props = {
    title: string,
    description: string,
    link: string,
    color: string,
}

const ProjectCard = (props: Props) => {
    return (
        <a href={props.link} target="_blank" rel="noreferrer">
            <div className="projectcard-wrapper">
                <div className="projectcard-title" style={{ backgroundColor: props.color }}>
                    <span>{props.title}</span>
                </div>
                <div className="projectcard-description" style={{ backgroundColor: props.color }}>
                    <span>{props.description}</span>
                </div>
            </div>
        </a>
    )
}

export default ProjectCard;