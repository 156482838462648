//Styling
import './HeaderLanguages.scss';

//i18n
import { useTranslation } from 'react-i18next';

const HeaderLanguages = () => {

    const [, i18n] = useTranslation('common');

    const changeLanguage = (language:string) => {
        i18n.changeLanguage(language);
    }

    return(
        <div className="headerlanguages-wrapper">
            <button className={"headerlanguages-button" + (i18n.language === 'es' ? "" : "-notactive")} onClick={() => changeLanguage('es')}>🇪🇸</button>
            <button className={"headerlanguages-button" + (i18n.language === 'en' ? "" : "-notactive")} onClick={() => changeLanguage('en')}>🇬🇧</button>
        </div>
    )
}

export default HeaderLanguages;