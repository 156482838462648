import * as React from 'react';

//Styling
import './Header.scss';

//Child components
import HeaderNavbar from './components/HeaderNavbar/HeaderNavbar';
import HeaderLanguages from './components/HeaderLanguages/HeaderLanguages';

const Header = () => {
    return(
        <div className="header-wrapper" id="header">
            <div className="header-languages">
                <HeaderLanguages/>
            </div>
            <div className="header-title">
                <span>JOAN BOIX</span>
            </div>
            <div className="header-navbar">
                <HeaderNavbar />
            </div>
        </div>
    )
}

export default Header;