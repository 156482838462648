export const projects = [
  {
    name: "tictactoe",
    link: "https://tictactoe.joanboix.dev",
    color: "#46ED61",
  },
  {
    name: "sortingalgorithmvisualizer",
    link: "https://sorting.joanboix.dev",
    color: "#C91F57",
  },
  {
    name: "todoapp",
    link: "https://todo.joanboix.dev",
    color: "#C3073F",
  },
  {
    name: "gameoflife",
    link: "https://gof.joanboix.dev",
    color: "#EEBB33",
  },
  {
    name: "postsapp",
    link: "https://joanb0ix.github.io/posts-app-cleverpy/",
    color: "#C5C5C5",
  },
  {
    name: "summath",
    link: "https://summath.joanboix.dev",
    color: "#4167C4",
  },
  {
    name: "levenshteindistance",
    link: "https://levenshtein.joanboix.dev",
    color: "#457F25",
  },
];
