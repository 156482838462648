import React from 'react';

//Styling
import './App.scss';

//Child components
import Navbar from './components/Navbar/Navbar';
import Header from './components/Header/Header';
import Portfolio from './components/Portfolio/Portfolio';
import PersonalInfo from './components/PersonalInfo/PersonalInfo';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';

function App() {

  const [scrollY, setScrollY] = React.useState(window.pageXOffset);

  window.onscroll = function() {
    setScrollY(window.pageYOffset);
  }

  return (
    <div className="app-wrapper">
      <div className={"app-stickynav" + (scrollY > 600 ? "-active" : "")}>
        <Navbar />
      </div>
      <Header />
      <Portfolio />
      <PersonalInfo />
      <Contact /> 
      <Footer />
    </div>
  );
}

export default App;
